import { create } from 'zustand';

interface FAQStore {
  selectedCategoryUID: number;
  setSelectedCategoryUID: (uid: number) => void;
}

export const useFAQStore = create<FAQStore>((set) => ({
  selectedCategoryUID: 0,
  setSelectedCategoryUID: (uid) => set({ selectedCategoryUID: uid }),
}));
